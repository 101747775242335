.filter-accordian {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 22%;
  margin-right: 35%;

  &__container {
    width: 120%;
    box-shadow: none !important;
    margin: 0 !important;
  }

  &__container:before {
    display: none;
  }

    &__category{
        color: #000;
        font-family: "BemboStd" !important;
        font-weight: 500 !important;
        font-size: 1.4em !important;
        white-space: nowrap;
    }

    &__list{
        cursor: pointer;
        margin-top: -3%;
        text-transform: uppercase;
        font-size: 10px !important;
    }

  &__item {
    list-style: none;
    margin-bottom: 2%;
    margin-left: 2%;
  }
}
