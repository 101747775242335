
.bestSellers-container {

  &__heading {
    float: left;
  }

  &__kpiContainer {
    float: right;
    margin: 0 20px 0 0;
    height: 100%;
    line-height: 5;
  }

  &__kpi {
    float: left;
    margin: 0 0 0 30px;
    list-style-position: inside;
    text-align: right;
  }

  &__card-grid {
    margin-bottom: 1% !important;
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  &__item {
    margin-bottom: 1% !important;
  }

  &__container {
    overflow: hidden;
  }

  &__sketch {
    width: 41%;
    height: auto;
  }
}
  
  





