.navbarmaster {
  background-color: #000 !important;
  height: 50px;
  padding-left: 30px;
  padding-right: 30px;
  justify-content: space-between !important;
  align-items: center;
  box-shadow: none !important;
}

.amqlogo {
  height: 20px;
  width: auto;
}

.amq-logo-invert { 
  filter: invert(1);
}

.username {
  text-transform: capitalize;
  font-size: 14px;
  font-family: HelveticaNeueLTStd-Lt !important;
  color: white !important; 
}
