.modal {
  &__box {
    display: flex;
    flex-direction: column;
    top: 35%;
    left: 50%;
    align-items: center;
    width: 600px;
    position: absolute;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 24px 50px 10px 10px !important;
    height: fit-content;
    box-shadow: 0 0 6px 6px rgb(0 0 0 / 8%);
    -webkit-box-shadow: 0 0 6px 6px rgb(0 0 0 / 8%);
    padding: 4.5em !important;
  }

  &__box:focus {
    outline: 0px !important;
  }

  &__pdfbutton {
    background-color: black !important;
    color: white !important;
    width: 148px;
    border-radius: 0 !important;
    height: 42px;
    font-size: 10px !important;
  }

  &__conatainer {
    .MuiBackdrop-root {
      background-color: hsla(0, 0%, 100%, 0.65);
    }
  }

  &__description {
    margin-top: 2em;
  }

  &__list {
    list-style-type: none;
    font-family: "BemboStd" !important;
  }

  &__list-text {
    font-size: 1.5em;
    margin: 9px 24px !important;
    font-family: "BemboStd" !important;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    };
    border: none;
    background: none;
  }

  &__list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 2.2em;
    margin: 0 !important;
    margin-top: 2em;
  }

  &__close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    color: rgba(0, 0, 0, 0.87);

    &:hover {
      cursor: pointer;
    }
  }

  &__icons {
    display: flex;
  }

  &__spinner-container {
    color: #4bb543 !important;
    height: 20px !important;
    width: 20px !important;
  }

  &__spinner {
    display: none;
  }

  &__spinner--active {
    display: block;
  }

  &__failed{
    display: none;
  }

  &__failed--active{
    display: block;
    color: red;
  }

  &__download {
    min-height: 14px;
    display: none;
    background-color: black;
    color: white;
    text-decoration: none;
    padding: 2px;
    cursor: pointer;
    
  }

  &__download--active {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__checkmark {
    display: none;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    stroke-width: 5;
    stroke: #4bb71b;
    stroke-miterlimit: 10;
    animation: fill 0.4s ease-in-out 0.4s forwards,
      scale 0.3s ease-in-out 0.9s both;
    margin-top: 10%;
    margin-left: -6%;

    &__circle {
      stroke-dasharray: 166;
      stroke-dashoffset: 166;
      stroke-width: 5;
      stroke-miterlimit: 10;
      stroke: #4bb71b;
      fill: #fff;
      animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    }

    &__check {
      transform-origin: 50% 50%;
      stroke-dasharray: 48;
      stroke-dashoffset: 48;
      animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    }
  }

  &__checkmark--active {
    display: block;
  }
}

.disabled { 
    opacity: .5 !important;
}