.loading{
    margin-top: 200px !important;
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__text{
        margin-bottom: 30px;
        text-align: center;
        font-size: 20px;
      }
    }
  

  