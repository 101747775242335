.page {
  &__header {
    display: flex;
    justify-content: space-between;
    margin: 0 25px;
    margin-bottom: 20px; 
    text-align: center;
  }
  
  &__title {
    font-size: 2.9em;
    font-family: "BemboStd" !important;
    justify-content: center;
    display: grid;
  }

  &__container {
    min-height: 46px !important;
    justify-content: center !important;
    display: inherit !important;
    justify-content: end !important;
    overflow: hidden;
  }

  &__season-header { 
    font-size: 0.6em !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }

  &__drawer .MuiPaper-root { 
      position: relative !important;
  }


  &__drawer-toggle {
    background-color: transparent !important;
    min-width: unset !important;
  }

  &__drawer-toggle-icon {
    color: rgba(0, 0, 0, 0.87);
  }

  &__drawer-report-week {
    font-family: HelveticaNeueLTStd-Lt !important;
  }
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  position: relative !important;
  z-index: 100;
}
