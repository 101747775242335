@import "variables";
@import "../font/MyFontsWebfontsKit.css";

a {
  text-decoration: none;
  color: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html { 
  font-size: 62.5%;
}

body ::-webkit-scrollbar {
  -webkit-appearance: none !important;
  display: none !important;
}

// ---------- Helper Functions ---------- //
input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.display_flex {
    display: flex;
}

.selected { 
  font-weight: bold;
}

.display_justify { 
  display: flex;
  justify-content: center;
}

.display_justifyAlign { 
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-Col { 
  flex-direction: column;
}

.cursor_pointer {
    cursor: pointer;
}

h2 {
  font-size: 16px;
  color: #000;
  font-family: "BemboStd" !important;
  font-weight: 500;
}

.container-heading { 
  margin-top: 3%;
}

.app {
  &__loading{
  margin-top: 200px !important;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  }

  &__loading-text{
    margin-bottom: 30px;
    text-align: center;
    font-size: 20px;
  }
}