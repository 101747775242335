
.card{
  
  display: inline-flex;
  font-family: HelveticaNeueLTStd-Lt !important;

  &__img-holder{
    width: 50%;

      &--large-card{
        width: 20%;
      }
  }

  &__img { 
    width: 85%;
    height: auto;
  } 

  &__desc-box{
    width: 50%;

    &--large-card{
      width: 80%;
    }
  }

  &__description { 
    display: grid;
    padding: 10px 3px 6.5px 3px;

      &--large-card { 
        display: flex;
        flex-direction: column;
        align-items: center;     
      }
  }

  &__sales-description { 
    padding: 0.5em 0;
    margin-right: 30px;

      &--largeCard { 
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0.5em 0;
      }
  }

  &__OH-desc { 
    text-decoration: underline;
    margin-top: 3%;
  } 

  &__sales-table--large-card{
    padding:0  15px;
  }

  &__description span { 
    margin-bottom: 1.5%;
  }

  &__table-header{
    font-weight: 600;
    white-space: nowrap;
  }

  .MuiDivider-root{
     width: 95%;
  }

  &__ICONMCO-box { 
    display: flex;
    justify-content: flex-end;
  }

  &__ICONMCO-productTag { 
    background-color: #b31d26 !important;
    color: #fff!important;
    border: none!important;
    font-size: 10px!important;
    text-transform: uppercase!important;
    white-space: nowrap!important;
    font-family: HelveticaNeueLTStd-Lt!important;
    border-radius: 0!important;
    max-width: 42px !important;
    max-height: 18px !important;
    min-width: 42px !important;
    min-height: 18px !important;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-right: 4%;
  }


}

